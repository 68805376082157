import { getFocusableElements, getFormContentInnerSelector } from './elements';
import { FocusLocker } from './types';
import { isVisibleElement } from './utils';

function getFirst<T extends HTMLElement>(elementList: T[]): T | undefined {
    return elementList[0];
}

function getLast<T extends HTMLElement>(elementList: T[]): T | undefined {
    return elementList[elementList.length - 1];
}

function handleBoundaries({ elementList, navigatingBack }: { elementList: HTMLElement[]; navigatingBack: boolean }): boolean {
    const lastElement = getLast(elementList);
    const firstElement = getFirst(elementList);

    const boundaryElement = navigatingBack ? firstElement : lastElement;
    const elementToFocus = navigatingBack ? lastElement : firstElement;

    const isInScope = elementList.some((element) => document.activeElement === element);

    if (isInScope && document.activeElement !== boundaryElement) {
        return false;
    }

    elementToFocus.focus();

    return true;
}

function createFocusTrap({ element, focusableElements }: { element: HTMLElement; focusableElements: HTMLElement[] }): () => void {
    const handler = (evt: FocusEvent): unknown => {
        if (!('tagName' in evt.target)) {
            getFirst(focusableElements).focus({ preventScroll: true });
            return;
        }
        if (element.contains(evt.target as HTMLElement)) {
            return;
        }

        evt.preventDefault();
        evt.stopPropagation();
        evt.stopImmediatePropagation();
        getFirst(focusableElements).focus({ preventScroll: true });
    };

    const originalFocus = document.onfocus;
    document.onfocus = handler;
    document.addEventListener('focus', handler, true);
    document.addEventListener('focus', handler);

    return () => {
        document.onfocus = originalFocus;
        document.removeEventListener('focus', handler, true);
        document.removeEventListener('focus', handler);
    };
}

type FocusLockHandle = ReturnType<typeof handleFocusLock>;

function handleFocusLock(element: HTMLElement): () => void {
    const focusableElements = getFocusableElements(element).filter(isVisibleElement);

    const handler = (e: KeyboardEvent): void => {
        const isTabPressed = e.key === 'Tab';

        if (!isTabPressed) return;
        if (!focusableElements.length) {
            e.preventDefault();
            return;
        }

        handleBoundaries({ elementList: focusableElements, navigatingBack: e.shiftKey }) && e.preventDefault();
    };

    document.addEventListener('keydown', handler);
    const destroyFocusTrap = createFocusTrap({ element, focusableElements });

    return () => {
        document.removeEventListener('keydown', handler);
        destroyFocusTrap();
    };
}

let locker: FocusLocker;

export const getFocusLocker = (): FocusLocker => {
    return locker;
};

export function createFocusLocker(): void {
    let cleanupFocusLock: FocusLockHandle | null = null;

    const clear = (): void => {
        if (!cleanupFocusLock) return;

        cleanupFocusLock();
        cleanupFocusLock = null;
    };

    locker = {
        lock: (element: HTMLElement) => {
            clear();
            cleanupFocusLock = handleFocusLock(element);
        },
        clear
    };
}

export const focusFormContent = (formID: string): void => {
    const formContent: HTMLElement = document.querySelector(getFormContentInnerSelector(formID));

    formContent?.focus({ preventScroll: true });
};
