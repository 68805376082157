import { getContext } from '../context';
import { getForm } from '../state';
import { SubscribeFormData, Step } from '../types';
import { EventFormDetails, FormEvent } from './types';

const FORMS_EVENTS_NAME = 'omnisendForms';

type StepEventProps = {
    formID: string;
    step: Step;
};

type SubmitEventProps = {
    formID: string;
    formValues: SubscribeFormData;
};

const getEventFormDetails = (formID: string): EventFormDetails => {
    const form = getForm(formID);

    return {
        id: form.formID,
        name: form.formName,
        displayType: form.displayType,
        versionID: form.versionID,
        versionName: form.versionName
    };
};

export const dispatchViewEvent = ({ formID }: { formID: string }): void => {
    const { brand } = getContext();
    const viewEvent = new CustomEvent<FormEvent<'view'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'view',
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID)
        }
    });
    window.dispatchEvent(viewEvent);
};

export const dispatchInteractionEvent = ({ formID }: { formID: string }): void => {
    const { brand } = getContext();
    const interactionEvent = new CustomEvent<FormEvent<'interaction'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'interaction',
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID)
        }
    });
    window.dispatchEvent(interactionEvent);
};

export const dispatchSubmitEvent = ({ formID, formValues }: SubmitEventProps): void => {
    const { brand } = getContext();
    const submitEvent = new CustomEvent<FormEvent<'submit'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'submit',
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID),
            formValues
        }
    });
    window.dispatchEvent(submitEvent);
};

export const dispatchCloseEvent = ({ formID }: { formID: string }): void => {
    const { brand } = getContext();
    const closeEvent = new CustomEvent<FormEvent<'close'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'close',
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID)
        }
    });
    window.dispatchEvent(closeEvent);
};

export const dispatchStepViewEvent = ({ formID, step }: StepEventProps): void => {
    const { brand } = getContext();
    const viewEvent = new CustomEvent<FormEvent<'stepView'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'stepView',
            step,
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID)
        }
    });
    window.dispatchEvent(viewEvent);
};

export const dispatchStepInteractionEvent = ({ formID, step }: StepEventProps): void => {
    const { brand } = getContext();
    const interactionEvent = new CustomEvent<FormEvent<'stepInteraction'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'stepInteraction',
            step,
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID)
        }
    });
    window.dispatchEvent(interactionEvent);
};

export const dispatchStepSubmitEvent = ({ formID, formValues, step }: SubmitEventProps & StepEventProps): void => {
    const { brand } = getContext();
    const submitEvent = new CustomEvent<FormEvent<'stepSubmit'>>(FORMS_EVENTS_NAME, {
        detail: {
            type: 'stepSubmit',
            step,
            brandID: brand.getBrandID(),
            form: getEventFormDetails(formID),
            formValues
        }
    });
    window.dispatchEvent(submitEvent);
};
