import { getContext } from '../context';
import { ActionType, FormAction } from '../types';

const TIMEOUT = 5000;

export const autoRedirectAction = (actions: FormAction[]): void => {
    const autoRedirectUrl = actions.find((action) => action.type === ActionType.autoRedirect)?.settings?.url;

    if (!autoRedirectUrl) {
        return;
    }

    const { navigation } = getContext();

    setTimeout(() => {
        navigation.redirectWithContactID(autoRedirectUrl);
    }, TIMEOUT);
};
