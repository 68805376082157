import { ListenerOptions } from '../../../../types';
import { getFormTeaserButtonSelector, getFormTeaserCloseButtonSelector } from '../../../../elements';
import { attachClickTriggerListeners } from '../../../../event-listeners';
import { safeExecute } from '../../../../utils';

export const attachTeaserListeners = ({ form, element, additionalActions = [] }: ListenerOptions, showForm: () => void): void => {
    const { id } = form.data;

    const teaserButtonElement = element.querySelector(getFormTeaserButtonSelector(id));

    if (teaserButtonElement) {
        attachClickTriggerListeners(teaserButtonElement, () => {
            showForm();
            additionalActions.map(safeExecute);
        });
    }
};

export const attachTeaserCloseButtonListeners = (
    { form, element, additionalActions = [] }: ListenerOptions,
    closeTeaser: () => void
): void => {
    const { id } = form.data;

    const teaserCloseButtonElement = element.querySelector(getFormTeaserCloseButtonSelector(id));

    if (teaserCloseButtonElement) {
        attachClickTriggerListeners(teaserCloseButtonElement, () => {
            closeTeaser();
            additionalActions.map(safeExecute);
        });
    }
};
