import { CONTACT_ID_COOKIE } from './constants';
import { getContext } from './context';
import { Field, Form, FormContactIdentifier, FormData, SubscribeResult } from './types';

export function createForm(data: FormData, element: HTMLElement): Form {
    const isMultistep = data.steps?.length > 0;
    const isBackInStock = !!data.targeting?.backInStock;
    let currentStepIndex = 0;
    let currentStepID = isMultistep ? data.steps[currentStepIndex].ID : '';

    let contactIdentifier: FormContactIdentifier = { contactID: '', unconfirmedContactID: '' };

    const getCurrentStepID = (): string => {
        return currentStepID;
    };

    const isLastStep = (): boolean => {
        if (!isMultistep) {
            return true;
        }

        return currentStepIndex === data.steps.length - 1;
    };

    const changeToNextStep = (): void => {
        if (!isLastStep()) {
            currentStepIndex++;
            currentStepID = data.steps[currentStepIndex].ID;
        }
    };

    const changeToFirstStep = (): void => {
        if (isMultistep) {
            currentStepIndex = 0;
            currentStepID = data.steps[currentStepIndex].ID;
        }
    };

    const getCurrentStepFields = (): Field[] => {
        if (!isMultistep) {
            return data.fields;
        }

        return data.fields.filter((field) => field.stepID === currentStepID);
    };

    const getContactIdentifier = (): FormContactIdentifier => {
        return contactIdentifier;
    };

    const saveContactIdentifier = (subscribeResult: SubscribeResult): void => {
        const { contactID, unconfirmedContactID } = subscribeResult;

        if (contactID) {
            getContext().cookies.set(CONTACT_ID_COOKIE, contactID);
        }

        contactIdentifier = { contactID, unconfirmedContactID };
    };

    return {
        data,
        element,
        isMultistep,
        isBackInStock,
        getCurrentStepID: getCurrentStepID,
        changeToNextStep: changeToNextStep,
        changeToFirstStep: changeToFirstStep,
        isLastStep: isLastStep,
        getCurrentStepFields: getCurrentStepFields,
        getContactIdentifier: getContactIdentifier,
        saveContactIdentifier: saveContactIdentifier
    };
}
