import { getBlockActionSelector, getFormContainerElement } from '../../../../elements';
import { ActionType, DisplayType, ListenerOptions } from '../../../../types';
import { markFormClosed, safeExecute } from '../../../../utils';
import { attachClickTriggerListeners } from '../../../../event-listeners';
import { closeAction } from '../actions/close';
import { redirectAction } from '../../actions/redirect';
import { nextStepAction } from '../../../../actions/next-step';
import { getFocusLocker } from '../../../../accessibility';

export const attachFormActionsListeners = ({ form, element, additionalActions = [] }: ListenerOptions): void => {
    const { actions, id, mainFormId } = form.data;

    if (!actions) {
        return;
    }

    actions.forEach((action) => {
        const actionElement = element.querySelector(getBlockActionSelector(id, action.targetID));

        if (action.type === ActionType.redirect && actionElement) {
            attachClickTriggerListeners(
                actionElement,
                () => {
                    markFormClosed(mainFormId || id);
                    redirectAction(action.settings.url, form);
                    additionalActions.map(safeExecute);
                },
                true
            );
        }

        if (action.type === ActionType.close && actionElement) {
            attachClickTriggerListeners(
                actionElement,
                () => {
                    closeAction(form);
                    additionalActions.map(safeExecute);
                },
                true
            );
        }

        if (action.type === ActionType.nextStep && actionElement) {
            attachClickTriggerListeners(
                actionElement,
                async () => {
                    await nextStepAction(form);

                    if (form.data.displayType !== DisplayType.flyout) {
                        const formContainer = getFormContainerElement({ parentElement: document.body, formID: form.data.id });
                        getFocusLocker().lock(formContainer);
                    }
                },
                true
            );
        }
    });
};
