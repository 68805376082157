import { CENTER_X, CENTER_Y, RADIUS, SVG_NAMESPACE_URI, TEXT_DISTANCE } from './constants';

const TEXT_HEIGHT = 80;
const TEXT_WIDTH = (RADIUS / 2) * 1.1;

const setTextWrapperStyles = (element: HTMLElement, textColor: string): void => {
    element.style.color = textColor;
    element.style.display = 'flex';
    element.style.alignItems = 'center';
    element.style.height = '100%';
    element.style.justifyContent = 'flex-end';
};

export const createSliceLabel = ({
    startAngle,
    sliceAngle,
    textColor,
    text,
    sliceID
}: {
    startAngle: number;
    sliceAngle: number;
    textColor: string;
    text: string;
    sliceID: string;
}): SVGForeignObjectElement => {
    const textAngle = startAngle + sliceAngle / 2;
    const textX = CENTER_X + TEXT_DISTANCE * Math.cos((textAngle * Math.PI) / 180);
    const textY = CENTER_Y + TEXT_DISTANCE * Math.sin((textAngle * Math.PI) / 180);

    const foreignObject = document.createElementNS(SVG_NAMESPACE_URI, 'foreignObject');
    foreignObject.setAttribute('x', (textX - TEXT_WIDTH / 2).toString()); // Center the text horizontally
    foreignObject.setAttribute('y', (textY - TEXT_HEIGHT / 2).toString()); // Adjust for vertical centering
    foreignObject.setAttribute('width', TEXT_WIDTH.toString()); // Fixed width for text wrapper
    foreignObject.setAttribute('height', TEXT_HEIGHT.toString()); // Enough height for wrapped text
    foreignObject.setAttribute('transform', `rotate(${textAngle}, ${textX}, ${textY})`);

    const textWrapper = document.createElement('div');
    setTextWrapperStyles(textWrapper, textColor);
    textWrapper.textContent = text;
    textWrapper.setAttribute('id', sliceID);
    textWrapper.setAttribute('data-slice', 'true');

    foreignObject.appendChild(textWrapper);

    return foreignObject;
};

export const createSliceElement = ({
    startAngle,
    endAngle,
    sliceAngle,
    backgroundColor
}: {
    startAngle: number;
    endAngle: number;
    sliceAngle: number;
    backgroundColor: string;
}): SVGPathElement => {
    const startX = CENTER_X + RADIUS * Math.cos((startAngle * Math.PI) / 180);
    const startY = CENTER_Y + RADIUS * Math.sin((startAngle * Math.PI) / 180);
    const endX = CENTER_X + RADIUS * Math.cos((endAngle * Math.PI) / 180);
    const endY = CENTER_Y + RADIUS * Math.sin((endAngle * Math.PI) / 180);

    const sliceElement = document.createElementNS(SVG_NAMESPACE_URI, 'path');
    const largeArcFlag = sliceAngle > 180 ? 1 : 0;

    sliceElement.setAttribute(
        'd',
        `
    M ${CENTER_X},${CENTER_Y}
    L ${startX},${startY}
    A ${RADIUS},${RADIUS} 0 ${largeArcFlag} 1 ${endX},${endY}
    Z
  `
    );
    sliceElement.setAttribute('fill', backgroundColor);
    return sliceElement;
};
