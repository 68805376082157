export const RADIUS = 180;
export const CENTER_X = 200;
export const CENTER_Y = 200;
export const TEXT_DISTANCE = RADIUS * 0.65;
export const WOF_BORDER_THICKNESS = 5;

export const SMALL_CIRCLE_RADIUS = 4;
export const SMALL_CIRCLE_DISTANCE = RADIUS + SMALL_CIRCLE_RADIUS + 4;

export const SHADOW_WOF_OPACITY = '0.3';
export const SHADOW_WOF_COLOR = '#000000';
export const WOF_OUTER_COLOR = '#FFFFFF';

export const SVG_NAMESPACE_URI = 'http://www.w3.org/2000/svg';
