import { closeAction } from '../actions/close';
import { ActionType, ListenerOptions } from '../../../../types';
import { safeExecute } from '../../../../utils';
import { getFormBrandingSelector, getFormContentInnerSelector } from '../../../../elements';

type GlobalListenerOptions = Omit<ListenerOptions, 'element'>;

const handleEscapeButton = ({ form, additionalActions }: GlobalListenerOptions): void => {
    document.addEventListener('keydown', function keydownListener(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            event.preventDefault();
            closeAction(form);
            additionalActions.map(safeExecute);
            document.removeEventListener('keydown', keydownListener);
        }
    });
};

const handleClickOutside = ({ form, additionalActions }: GlobalListenerOptions): void => {
    // Using setTimeout to ensure the document click listener is added
    // after the current call stack is cleared
    setTimeout(() => {
        document.addEventListener('click', function outsideClickListener(event: MouseEvent) {
            const formContent = document.querySelector(getFormContentInnerSelector(form.data.id));
            const formBranding = document.querySelector(getFormBrandingSelector(form.data.id));
            const targetElement = event.target as Element;
            const isOutside = [formContent, formBranding].every((element) => !element?.contains(targetElement));

            if (isOutside) {
                event.preventDefault();
                event.stopPropagation();
                closeAction(form);
                additionalActions.map(safeExecute);
                document.removeEventListener('click', outsideClickListener);
            }
        });
    }, 0);
};

export const attachGlobalListeners = ({ form, additionalActions = [] }: GlobalListenerOptions): void => {
    handleEscapeButton({ form, additionalActions });

    if (form.data.actions?.find((action) => action.type === ActionType.clickOutside)) {
        handleClickOutside({ form, additionalActions });
    }
};
