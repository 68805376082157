import { getContext } from '../../../context';
import { PopupForm } from './types';

const OPEN_FORM_EVENT = 'openForm';

export const monitorCustomTriggerOnlyForms = (forms: PopupForm[]): void => {
    const { api } = getContext();

    api.registerCallback((data) => {
        if (!data?.length) {
            return;
        }
        const [eventType, formId] = data;

        if (eventType === OPEN_FORM_EVENT) {
            const form = forms.find((form) => {
                if (form.data.abSetupId) {
                    return form.data.mainFormId === formId;
                }

                return form.data.id === formId;
            });

            if (!form) {
                return;
            }

            form.show();
        }
    });
};
