import { checkBackInStockTargeting } from '../back-in-stock-targeting';
import { InshopFormData } from '../types';
import { EmbeddedForm } from './types';
import { createEmbeddedForm, getEmbeddedFormContainer } from './embedded-form';

const checkBackInStockForms = (forms: EmbeddedForm[]): void => {
    forms.forEach((form) => {
        const isVisible = form.isFormVisible();
        const shouldShowBackInStockForm = checkBackInStockTargeting(form.data);

        if (isVisible && !shouldShowBackInStockForm) {
            form.hide();
        }

        if (!isVisible && shouldShowBackInStockForm) {
            form.show();
        }
    });
};

export const initiate = async (forms: InshopFormData[]): Promise<void> => {
    const detectedEmbeddedForms = forms.filter((form) => {
        return !!getEmbeddedFormContainer(form.id);
    });

    const embeddedForms: EmbeddedForm[] = detectedEmbeddedForms.map((data) => createEmbeddedForm(data));
    embeddedForms.filter((form) => !form.data.targeting?.backInStock).forEach((form) => form.show());

    const backInStockForms = embeddedForms.filter((form) => form.data.targeting?.backInStock);
    if (backInStockForms.length) {
        setInterval(() => {
            checkBackInStockForms(backInStockForms);
        }, 1000);
    }
};
