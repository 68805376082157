import { getFormVersionSelectedCookie, markFormVersionSelected } from '../../utils';
import { AbSetup, AbSetupVersion, InshopFormData } from './types';

export const enrichFormWithMainForm = (form: InshopFormData, abSetups: AbSetup[]): InshopFormData => {
    if (form.abSetupId) {
        const formAbSetup = abSetups.find((abSetup) => abSetup.id === form.abSetupId);
        return {
            ...form,
            mainFormId: formAbSetup?.formID,
            mainFormName: formAbSetup?.formName
        };
    }

    return form;
};

const getRandomVersionFormId = (versions: AbSetupVersion[]): string => {
    const score = Math.random() * 100;

    let boundariesStart = 0;
    let boundariesEnd = 0;

    for (let index = 0; index < versions.length; index++) {
        const version = versions[index];
        const isLastVersion = versions.length - 1 === index;

        boundariesEnd = isLastVersion ? 100 : boundariesStart + version.weight;

        if (score >= boundariesStart && score <= boundariesEnd) {
            return version.formID;
        }

        boundariesStart = boundariesEnd;
    }
};

export const getAbSetupFormsSelectedVersions = (forms: InshopFormData[], abSetups: AbSetup[]): InshopFormData[] => {
    const selectedVersionIds = abSetups.reduce((formIds, { versions }) => {
        const alreadySelectedVersionFormId = versions.find((version) => !!getFormVersionSelectedCookie(version.formID))?.formID;

        if (alreadySelectedVersionFormId) {
            return [...formIds, alreadySelectedVersionFormId];
        }

        const selectedVersionFormId = getRandomVersionFormId(versions);

        if (selectedVersionFormId) {
            markFormVersionSelected(selectedVersionFormId);
            return [...formIds, selectedVersionFormId];
        }

        return formIds;
    }, []);

    return forms.filter((form) => selectedVersionIds.includes(form.id));
};
