import { getOmnisendContext } from '../../context';
import { InshopFormData } from './types';

export function checkBackInStockTargeting(form: InshopFormData): boolean {
    if (!form.targeting?.backInStock) {
        return true;
    }

    return isProductVariantOutOfStock();
}

export const isProductVariantOutOfStock = (): boolean => {
    const productInfo = getOmnisendContext().getProductInfo();
    return productInfo?.status === 'outOfStock';
};
