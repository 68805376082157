import { InshopFormData } from './modules/inshop-form/types';
import { DisplayType, FormData } from './types';

type Form = {
    formID: string;
    formName: string;
    displayType: DisplayType;
    versionID?: string;
    versionName?: string;
};

type FormsByID = Record<string, Form>;

type InshopOrLandingPageFormData = FormData | InshopFormData;

const mapFormsDTOToFormsByID = (formData: InshopOrLandingPageFormData[]): FormsByID => {
    return formData.reduce((result, form) => {
        const formDetails = {
            displayType: form.displayType
        };

        if (form.abSetupId) {
            result[form.id] = {
                ...formDetails,
                formID: form.mainFormId,
                formName: form.mainFormName,
                versionID: form.id,
                versionName: form.name
            };
        } else {
            result[form.id] = {
                ...formDetails,
                formID: form.id,
                formName: form.name
            };
        }

        return result;
    }, {} as FormsByID);
};

let forms: FormsByID;

export const setFormsToState = (formsDTO: InshopOrLandingPageFormData[]): void => {
    forms = mapFormsDTOToFormsByID(formsDTO);
};

export const getForm = (formID: string): Form => forms[formID];
