import { evaluateFormTeaserTargeting } from './targeting';
import { PopupForm } from './types';

let forms: PopupForm[] = [];

export const initiateFormTeasers = (initialForms: PopupForm[]): void => {
    forms = initialForms;
};

export const hideFormsTeasers = (): void => {
    forms.forEach((form) => form.hideTeaser());
};

export const showAvailableFormsTeasers = (): void => {
    forms.forEach((form) => {
        const shouldShowTeaser = evaluateFormTeaserTargeting(form.data);

        if (!form.isTeaserVisible() && shouldShowTeaser) {
            form.showTeaser();
        }

        if (form.isTeaserVisible() && !shouldShowTeaser) {
            form.hideTeaser();
        }
    });
};
