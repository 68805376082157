import { getFormCountdownTimerA11ySelector, getFormCountdownTimerTextSelector } from '../elements';
import { ActionType, FormAction, FormData } from '../types';
import { isVisibleElement } from '../utils';

const SECOND_IN_MS = 1000;
const MINUTE_IN_MS = SECOND_IN_MS * 60;
const HOUR_IN_MS = MINUTE_IN_MS * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;

const normalizeNumber = (value: number): string => {
    const stringValue = value.toString();
    return stringValue.length === 1 ? `0${stringValue}` : stringValue;
};

const convertMsToTimerFormat = (ms: number): string => {
    const days = normalizeNumber(Math.floor(ms / DAY_IN_MS));
    const hours = normalizeNumber(Math.floor((ms % DAY_IN_MS) / HOUR_IN_MS));
    const minutes = normalizeNumber(Math.floor((ms % HOUR_IN_MS) / MINUTE_IN_MS));
    const seconds = normalizeNumber(Math.floor((ms % MINUTE_IN_MS) / SECOND_IN_MS));
    return `${days}:${hours}:${minutes}:${seconds}`;
};

const convertMsToTimeLeft = (ms: number): string => {
    const days = Math.floor(ms / DAY_IN_MS);
    const hours = Math.floor((ms % DAY_IN_MS) / HOUR_IN_MS);
    const minutes = Math.floor((ms % HOUR_IN_MS) / MINUTE_IN_MS);
    const seconds = Math.floor((ms % MINUTE_IN_MS) / SECOND_IN_MS);

    return `${days} ${days === 1 ? 'day' : 'days'} ${hours} ${hours === 1 ? 'hour' : 'hours'} ${minutes} ${
        minutes === 1 ? 'minute' : 'minutes'
    } ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
};

const getRemainingTimeInMs = (endDate: string): number => {
    const remainingTimeInMs = new Date(endDate).getTime() - new Date().getTime();
    return remainingTimeInMs < 0 ? 0 : remainingTimeInMs;
};

const initTimer = (formContainer: Element, formId: string, countdownTimerAction: FormAction): void => {
    const {
        targetID,
        settings: { endDateTime }
    } = countdownTimerAction;

    const countdownTimerTextNode = formContainer.querySelector(getFormCountdownTimerTextSelector(formId, targetID));
    const countdownTimerA11yNode = formContainer.querySelector(getFormCountdownTimerA11ySelector(formId, targetID));

    if (!isVisibleElement(countdownTimerTextNode)) {
        return;
    }

    if (countdownTimerTextNode) {
        countdownTimerTextNode.textContent = convertMsToTimerFormat(getRemainingTimeInMs(endDateTime));
    }

    if (countdownTimerA11yNode) {
        countdownTimerA11yNode.textContent = convertMsToTimeLeft(getRemainingTimeInMs(endDateTime));
    }

    const countdownTimerInterval = setInterval(() => {
        const remainingTimeInMs = getRemainingTimeInMs(endDateTime);
        if (countdownTimerTextNode) {
            countdownTimerTextNode.textContent = convertMsToTimerFormat(remainingTimeInMs);
        }
        if (remainingTimeInMs <= 0 || !isVisibleElement(countdownTimerTextNode)) {
            clearInterval(countdownTimerInterval);
        }
    }, 1000);

    const countdownTimerIntervalA11y = setInterval(() => {
        const remainingTimeInMs = getRemainingTimeInMs(endDateTime);
        if (countdownTimerA11yNode) {
            countdownTimerA11yNode.textContent = convertMsToTimeLeft(remainingTimeInMs);
        }
        if (remainingTimeInMs <= 0 || !isVisibleElement(countdownTimerTextNode)) {
            clearInterval(countdownTimerIntervalA11y);
        }
    }, 1000 * 10);
};

export const initCountdownTimer = (formContainer: Element, formData: FormData): void => {
    const countdownTimerActions = formData?.actions?.filter(
        (action) => action.type === ActionType.countdownTimer && action.settings?.endDateTime
    );

    if (!countdownTimerActions?.length) {
        return;
    }

    countdownTimerActions.forEach((countdownTimerAction) => {
        initTimer(formContainer, formData.id, countdownTimerAction);
    });
};
