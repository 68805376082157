import {
    getFormDiscountCodeCopyButtonSelector,
    getFormDiscountCodeSuccessIconSelector,
    getFormDiscountCodeTextSelector
} from '../elements';
import { copyToClipboard, hideElement, showElement } from '../utils';

export const initDiscountCode = (formID: string, discountCode: string): void => {
    if (!discountCode) {
        return;
    }

    const discountCodeTextElement: HTMLElement = document.querySelector(getFormDiscountCodeTextSelector(formID));
    const discountCodeCopyButton = document.querySelector(getFormDiscountCodeCopyButtonSelector(formID));
    const discountCodeSuccessIcon = document.querySelector(getFormDiscountCodeSuccessIconSelector(formID));

    if (!discountCodeTextElement) {
        return;
    }

    discountCodeTextElement.innerText = discountCode;

    const copyListener = async (): Promise<void> => {
        await copyToClipboard(discountCode);
        hideElement(discountCodeCopyButton);
        showElement(discountCodeSuccessIcon);
        discountCodeCopyButton.removeEventListener('click', copyListener);
    };

    discountCodeCopyButton?.addEventListener('click', copyListener);
};
