import { FORMS_CONTAINER_ID, FORMS_WRAPPER_ID } from '../../constants';
import { createContext, getContext } from '../../context';
import { DisplayType } from '../../types';
import { initiate as initiateEmbeddedForms } from './embedded';
import { initiate as initiatePopupForms } from './popup';
import { getCookie, setCookie } from './cookies';
import { setFormsToState } from '../../state';
import { createFocusLocker } from '../../accessibility';
import { getApplicableForms } from './loader/loader';
import { createTrackingContainer } from '../../tracking';

const setupFormsWrapperElement = (): void => {
    const formsWrapperElement = document.createElement('div');
    formsWrapperElement.setAttribute('id', FORMS_WRAPPER_ID);

    document.body.appendChild(formsWrapperElement);

    const formsContainer = document.createElement('div');
    formsContainer.setAttribute('id', FORMS_CONTAINER_ID);

    formsWrapperElement.appendChild(formsContainer);
    formsWrapperElement.appendChild(createTrackingContainer());
};

export const initiate = async (): Promise<void> => {
    createContext({
        get: getCookie,
        set: setCookie
    });

    const { shopify, navigation } = getContext();

    const forms = await getApplicableForms();
    const popupForms = forms.filter((form) => form.displayType === DisplayType.popup || form.displayType === DisplayType.flyout);
    const embeddedForms = forms.filter((form) => form.displayType === DisplayType.embedded);

    if (!popupForms.length && !embeddedForms.length) {
        return;
    }

    setupFormsWrapperElement();
    createFocusLocker();
    setFormsToState(forms);

    if (embeddedForms.length) {
        initiateEmbeddedForms(embeddedForms);
    }

    if (navigation.isProductReviewSubmitPage()) {
        return;
    }

    if (shopify.isDesignMode()) {
        return;
    }

    if (popupForms.length) {
        initiatePopupForms(popupForms);
    }
};
