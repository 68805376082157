import { getFormContentElement, getFormWofShadowSVGPlaceholder, getFormWofSVGPlaceholder } from '../../elements';
import { FormData } from '../../types';
import { createBaseCircleWithBorder, createSliceCircle } from './circle';
import { SHADOW_WOF_COLOR, SHADOW_WOF_OPACITY, SVG_NAMESPACE_URI, WOF_OUTER_COLOR } from './constants';
import { createSliceElement, createSliceLabel } from './slice';

type WheelOfFortuneSlice = {
    id: string;
    text: string;
    backgroundColor: string;
    textColor: string;
};

const getWheelOfFortuneSlicesConfiguration = (formID: string): WheelOfFortuneSlice[] => {
    const formContent = getFormContentElement({ parentElement: document.body, formID });
    const wheelOfFortuneJsonScript = formContent.querySelector('script[type="application/ld+json"]').textContent;

    let wheelOfFortuneSlices: WheelOfFortuneSlice[] = [];

    try {
        const wheelOfFortuneJson = JSON.parse(wheelOfFortuneJsonScript);
        wheelOfFortuneSlices = wheelOfFortuneJson.slices;
    } catch (error) {
        void error;
        return [];
    }

    return wheelOfFortuneSlices;
};

export const initWheelOfFortune = (form: FormData): void => {
    const wheelOfFortuneBlockID = form.actions?.find((action) => action.type === 'spin')?.targetID;

    if (!wheelOfFortuneBlockID) {
        return;
    }

    const formID = form.id;

    const wofSVGPlaceholder = document.querySelector<HTMLElement>(getFormWofSVGPlaceholder(formID, wheelOfFortuneBlockID));
    const wofShadowSVGPlaceholder = document.querySelector<HTMLElement>(getFormWofShadowSVGPlaceholder(formID, wheelOfFortuneBlockID));

    const wheelOfFortuneSlices = getWheelOfFortuneSlicesConfiguration(formID);

    if (!wheelOfFortuneSlices?.length) {
        throw 'Wheel of fortune slices configuration cannot be found';
    }

    if (!wofSVGPlaceholder || !wofShadowSVGPlaceholder) {
        return;
    }

    generateWofSVG(wofSVGPlaceholder, wheelOfFortuneSlices);
    generateWofShadowSVG(wofShadowSVGPlaceholder, wheelOfFortuneSlices);
};

const createSvgTitle = (text: string): SVGTitleElement => {
    const titleElement = document.createElementNS(SVG_NAMESPACE_URI, 'title');
    titleElement.textContent = text;

    return titleElement;
};

const getWofCircleParameters = (slicesCount: number): { sliceAngle: number; rotationOffset: number } => {
    const sliceAngle = 360 / slicesCount;
    return {
        sliceAngle,
        rotationOffset: sliceAngle / 2 + 90
    };
};

const generateWofShadowSVG = (svgElement: HTMLElement, slices: WheelOfFortuneSlice[]): void => {
    const { sliceAngle, rotationOffset } = getWofCircleParameters(slices.length);

    svgElement.setAttribute('aria-hidden', 'true');

    const baseCircle = createBaseCircleWithBorder();
    baseCircle.setAttribute('fill', SHADOW_WOF_COLOR);
    baseCircle.setAttribute('opacity', SHADOW_WOF_OPACITY);
    baseCircle.setAttribute('stroke', SHADOW_WOF_COLOR);
    svgElement.appendChild(baseCircle);

    slices.forEach((_, index) => {
        const sliceCircle = createSliceCircle({ sliceAngle, rotationOffset, index });
        sliceCircle.setAttribute('fill', SHADOW_WOF_COLOR);
        sliceCircle.setAttribute('opacity', SHADOW_WOF_OPACITY);

        svgElement.appendChild(sliceCircle);
    });
};

const generateWofSVG = (svgElement: HTMLElement, slices: WheelOfFortuneSlice[]): void => {
    const { sliceAngle, rotationOffset } = getWofCircleParameters(slices.length);

    svgElement.appendChild(createSvgTitle('Wheel of Fortune'));

    const baseCircle = createBaseCircleWithBorder();
    baseCircle.setAttribute('stroke', WOF_OUTER_COLOR);
    svgElement.appendChild(baseCircle);

    slices.forEach(({ backgroundColor }, index) => {
        const startAngle = sliceAngle * index - rotationOffset;
        const endAngle = sliceAngle * (index + 1) - rotationOffset;

        svgElement.appendChild(createSliceElement({ startAngle, endAngle, sliceAngle, backgroundColor }));

        const sliceCircle = createSliceCircle({ sliceAngle, rotationOffset, index });
        sliceCircle.setAttribute('fill', WOF_OUTER_COLOR);
        svgElement.appendChild(sliceCircle);
    });

    // Append texts after all other elements are rendered so the text would overflow on other slice if needed.
    slices.forEach(({ textColor, text, id }, index) => {
        const startAngle = sliceAngle * index - rotationOffset;
        svgElement.appendChild(createSliceLabel({ startAngle, sliceAngle, textColor, text, sliceID: id }));
    });
};
