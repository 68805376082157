export const attachClickTriggerListeners = (el: Element, listener: () => unknown, cleanListeners = false): void => {
    el.addEventListener('click', function clickListener(event) {
        event.preventDefault();
        listener();

        if (cleanListeners) {
            el.removeEventListener('click', clickListener);
        }
    });

    el.addEventListener('keydown', function keydownListener(event: KeyboardEvent) {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            listener();

            if (cleanListeners) {
                el.removeEventListener('keydown', keydownListener);
            }
        }
    });
};
