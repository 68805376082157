import { DATA_VALUE_ATTRIBUTE } from '../constants';
import {
    getFormFieldCheckboxLabelSelector,
    getFormFieldCheckboxValueSelector,
    getFormFieldRadioValueSelector,
    getFormFieldSelectSelector
} from '../elements';
import { Field } from '../types';

type ElementAttribute = 'aria-invalid' | 'aria-errormessage';

export const hideElement = (element: undefined | Element): void => {
    element?.setAttribute('style', 'display: none');
};

export const showElement = (element: undefined | Element): void => {
    element?.setAttribute('style', '');
};

export const isVisibleElement = (element: Element): boolean => {
    const box = element?.getBoundingClientRect();
    return box && !!box.width && !!box.height;
};

export const isFieldTypeSelect = (formElement: HTMLElement, formID: string, field: Field): boolean => {
    return !!formElement.querySelector(getFormFieldSelectSelector(formID, field.targetID, field.name));
};

export const isFieldTypeRadio = (formElement: HTMLElement, formID: string, field: Field): boolean => {
    return !!formElement.querySelector(getFormFieldRadioValueSelector(formID, field.targetID, field.name));
};

export const isFieldTypeCheckbox = (formElement: HTMLElement, formID: string, field: Field): boolean => {
    return !!formElement.querySelector(getFormFieldCheckboxLabelSelector(formID, field.targetID));
};

export const copyToClipboard = async (text: string): Promise<void> => {
    if (!navigator?.clipboard) {
        throw new Error('Clipboard API not available');
    }

    await navigator.clipboard.writeText(text);
};

export const getFormFieldDataValue = (element: Element): string => {
    return element?.getAttribute(DATA_VALUE_ATTRIBUTE);
};

export const getFormFieldCheckboxValues = (element: Element, formID: string, field: Field): string[] => {
    const result = new Array<string>();
    element.querySelectorAll(getFormFieldCheckboxValueSelector(formID, field.targetID, field.name)).forEach((el) => {
        const value = getFormFieldDataValue(el);
        result.push(value);
    });
    return result.length ? result : undefined;
};

export const addClassNameToElement = (element: HTMLElement, className: string): void => {
    element?.classList?.add(className);
};

export const removeClassNameFromElement = (element: HTMLElement, className: string): void => {
    element?.classList?.remove(className);
};

export const setElementAttributes = (element: HTMLElement, attributes: { key: ElementAttribute; value: string }[]): void => {
    attributes.forEach(({ key, value }) => {
        element?.setAttribute(key, value);
    });
};

export const removeElementAttributes = (element: HTMLElement, attributeKeys: ElementAttribute[]): void => {
    attributeKeys.forEach((key) => {
        element?.removeAttribute(key);
    });
};
