import {
    WOF_BORDER_THICKNESS,
    CENTER_X,
    CENTER_Y,
    RADIUS,
    SMALL_CIRCLE_DISTANCE,
    SMALL_CIRCLE_RADIUS,
    SVG_NAMESPACE_URI
} from './constants';

export const createBaseCircleWithBorder = (): SVGCircleElement => {
    const circle = document.createElementNS(SVG_NAMESPACE_URI, 'circle');

    circle.setAttribute('cx', CENTER_X.toString());
    circle.setAttribute('cy', CENTER_Y.toString());
    circle.setAttribute('r', (RADIUS + WOF_BORDER_THICKNESS / 2).toString());
    circle.setAttribute('stroke-width', WOF_BORDER_THICKNESS.toString());

    return circle;
};

export const createSliceCircle = ({
    sliceAngle,
    rotationOffset,
    index
}: {
    sliceAngle: number;
    rotationOffset: number;
    index: number;
}): SVGCircleElement => {
    const circleAngle = sliceAngle * index - rotationOffset;
    const circleX = CENTER_X + SMALL_CIRCLE_DISTANCE * Math.cos((circleAngle * Math.PI) / 180);
    const circleY = CENTER_Y + SMALL_CIRCLE_DISTANCE * Math.sin((circleAngle * Math.PI) / 180);

    const circle = document.createElementNS(SVG_NAMESPACE_URI, 'circle');
    circle.setAttribute('cx', circleX.toString());
    circle.setAttribute('cy', circleY.toString());
    circle.setAttribute('r', SMALL_CIRCLE_RADIUS.toString());

    return circle;
};
