import { FORMS_CONTAINER_ID } from '../constants';

export const getFormIDSelector = (formID: string): string => `omnisend-form-${formID}`;
export const getDeprecatedFormIdSelector = (formID: string): string => `soundest-form-${formID}`;
export const getFormLoadingContainer = (formID: string): string => `.omnisend-form-${formID}-loading-container`;
export const getFormErrorSelector = (formID: string): string => `.omnisend-form-${formID}-error`;
export const getFormBlockRequiredErrorSelector = (formID: string, blockID: string): string =>
    `#omnisend-form-${formID}-field-container-${blockID}-required`;
export const getFormBlockErrorSelector = (formID: string, blockID: string): string =>
    `#omnisend-form-${formID}-field-container-${blockID}-error`;
export const getFormBlockInputSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-input`;
export const getFormBlockFieldInputSelector = (formID: string, blockID: string, fieldName: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-input[name=${fieldName}]`;
export const getFormBlockLabelSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-label`;
export const getFormBlockContainerSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-block-container-${blockID}`;
export const getFormSuccessSections = (formID: string): string => `.omnisend-form-${formID}-success-sections-container`;
export const getFormSubscribedSections = (formID: string): string => `.omnisend-form-${formID}-subscribed-sections-container`;
export const getFormSections = (formID: string): string => `.omnisend-form-${formID}-sections-container`;
export const getUnavailablePageSections = (formID: string): string => `.omnisend-form-${formID}-unavailable-page-sections-container`;
export const getCloseActionSelector = (formID: string): string => `#${getFormIDSelector(formID)}-close-action`;
export const getBlockActionSelector = (formID: string, blockID: string): string => `#omnisend-form-${formID}-action-${blockID}`;
export const getStepIDSelector = (stepID: string): string => (!stepID ? '' : `-${stepID}`);
export const getSubmitFormSelector = (formID: string, stepID: string): string =>
    `#omnisend-form-${formID}${getStepIDSelector(stepID)}-submit-form`;
export const getPromotionalFormSelector = (formID: string, stepID: string): string =>
    `#omnisend-form-${formID}${getStepIDSelector(stepID)}-promotional-form`;
export const getSubmitFormButtonSelector = (formID: string): string => `#omnisend-form-${formID}-submit-form button[type=submit]`;

export const getPhoneNumberPrefixSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-phone-number-prefix`;
export const getPhoneNumberPrefixValueSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-phone-number-prefix-value`;
export const getPhoneNumberPrefixOptionsContainerSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-phone-number-prefix-options-container`;
export const getPhoneNumberPrefixOptionSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-phone-number-prefix-option`;
export const getPhoneNumberPrefixOptionValueSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-phone-number-prefix-option-value`;
export const getPhoneNumberPrefixOptionFlagSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-phone-number-prefix-option-flag`;

export const getFormFieldSelectSelector = (formID: string, blockID: string, fieldName: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-select[name=${fieldName}]`;
export const getFormFieldSelectTextSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-select-text`;
export const getFormFieldSelectPlaceholderSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-select-placeholder`;
export const getFormFieldSelectOptionsContainerSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-options-container`;
export const getFormFieldSelectOptionSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-option`;
export const getFormFieldSelectOptionValueSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-option-value`;

export const getFormFieldRadioValueSelector = (formID: string, blockID: string, fieldName: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-radio-button[name=${fieldName}]:checked`;
export const getFormFieldRadioSelector = (formID: string, blockID: string, fieldName: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-radio-button[name=${fieldName}]`;

export const getFormFieldCheckboxValueSelector = (formID: string, blockID: string, fieldName: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-checkbox[name=${fieldName}]:checked`;
export const getFormFieldCheckboxSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-checkbox`;
export const getFormFieldCheckboxLabelSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-field-container-${blockID}-checkboxField`;

export const getFormTeaserSelector = (formID: string): string => `.omnisend-form-${formID}-teaser`;
export const getFormTeaserButtonSelector = (formID: string): string => `#omnisend-form-${formID}-teaser-btn`;
export const getFormTeaserCloseButtonSelector = (formID: string): string => `#omnisend-form-${formID}-teaser-close-btn`;
export const getFormContainerSelector = (formID: string): string => `.omnisend-form-${formID}-container`;
export const getFormContentSelector = (formID: string): string => `.omnisend-form-${formID}-content`;
export const getFormContentInnerSelector = (formID: string): string => `.omnisend-form-${formID}-content-inner`;

export const getFormWofPointerSelector = (formID: string, blockID: string): string => `.omnisend-form-${formID}-wof-${blockID}-pointer`;
export const getFormWofPointerShadowSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-wof-${blockID}-pointer-shadow`;
export const getFormWofSelector = (formID: string, blockID: string): string => `#omnisend-form-${formID}-wof-${blockID}-svg`;
export const getFormWofShadowSelector = (formID: string, blockID: string): string => `#omnisend-form-${formID}-wof-${blockID}-shadow-svg`;
export const getFormWofSliceTextSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-wof-${blockID} [data-slice=true]`;
export const getFormWofWinningSliceA11ySelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-wof-${blockID}-winning-slice-a11y`;

export const getFormWofSVGPlaceholder = (formID: string, blockID: string): string => `#omnisend-form-${formID}-wof-${blockID}-svg`;
export const getFormWofShadowSVGPlaceholder = (formID: string, blockID: string): string =>
    `#omnisend-form-${formID}-wof-${blockID}-shadow-svg`;
export const getFormDiscountCodeTextSelector = (formID: string): string => `.omnisend-form-${formID}-discount-text`;
export const getFormDiscountCodeCopyButtonSelector = (formID: string): string => `.omnisend-form-${formID}-discount-copy-button`;
export const getFormDiscountCodeSuccessIconSelector = (formID: string): string => `.omnisend-form-${formID}-discount-success-icon`;

export const getEmbeddedFormContainerSelector = (formID: string): string => `#omnisend-embedded-v2-${formID}`;
export const getFormsContainerSelector = (): string => `#${FORMS_CONTAINER_ID}`;

export const getFormCountdownTimerTextSelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-countdown-timer-${blockID}-text`;
export const getFormCountdownTimerA11ySelector = (formID: string, blockID: string): string =>
    `.omnisend-form-${formID}-countdown-timer-${blockID}-a11y`;

export const getFormBrandingSelector = (formID: string): string => `.omnisend-form-${formID}-badge`;
