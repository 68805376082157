import { CONTACT_ID_COOKIE } from '../../../../constants';
import { SourceTargetingRuleType } from '../../../../types';
import { InshopFormData } from '../../types';

const hasQueryParams = (queryParams: string[]): boolean => {
    const params = new URLSearchParams(location.search);
    return queryParams.some((param) => !!params.get(param));
};

const hasGoogleAdsParams = (): boolean => hasQueryParams(['gclid', 'gad_source']);
const hasBingAdsParams = (): boolean => hasQueryParams(['msclkid']);
const hasOmnisendCommunicationParams = (): boolean => hasQueryParams([CONTACT_ID_COOKIE]);

const hasMarketingParams = (): boolean => hasGoogleAdsParams() || hasBingAdsParams() || hasOmnisendCommunicationParams();

const isReferrerGoogle = (referrer: string): boolean => referrer.includes('google.com');
const isReferrerYahoo = (referrer: string): boolean => referrer.includes('yahoo.com');
const isReferrerBing = (referrer: string): boolean => referrer.includes('bing.com');
const isReferrerFacebook = (referrer: string): boolean => referrer.includes('facebook.com');
const isReferrerInstagram = (referrer: string): boolean => referrer.includes('instagram.com');
const isDirect = (referrer: string): boolean => referrer === '';
const isOrganic = (referrer: string): boolean => isReferrerGoogle(referrer) || isReferrerBing(referrer) || isReferrerYahoo(referrer);

const sourceCheckMap: Record<SourceTargetingRuleType, (referrer: string) => boolean> = {
    googleAds: () => hasGoogleAdsParams(),
    organic: (referrer) => isOrganic(referrer) && !hasMarketingParams(),
    direct: (referrer) => isDirect(referrer) && !hasMarketingParams(),
    facebook: (referrer) => isReferrerFacebook(referrer),
    instagram: (referrer) => isReferrerInstagram(referrer),
    omnisendCommunication: () => hasOmnisendCommunicationParams()
};

export const checkSource = (form: InshopFormData): boolean => {
    const sourceTargeting = form.targeting?.source;

    if (!sourceTargeting) return true;

    const { includes = [], excludes = [] } = sourceTargeting;

    const referrer = document.referrer;

    if (includes.length) {
        return includes.some((rule) => sourceCheckMap[rule]?.(referrer));
    }

    if (excludes.length) {
        return excludes.every((rule) => !sourceCheckMap[rule]?.(referrer));
    }

    return true;
};
