import { getContext } from '../../../../context';
import { getFormContainerElement, getFormTeaserButtonElement } from '../../../../elements';
import { dispatchCloseEvent } from '../../../../event-dispatchers';
import { resetFormInteracted } from '../../../../tracking';
import { Form } from '../../../../types';
import { hideElement, markFormClosed } from '../../../../utils';
import { setFormClosedInSession } from '../activity-monitoring';
import { showAvailableFormsTeasers } from '../form-teaser';

export const closeAction = (form: Form): void => {
    const { id: formID, mainFormId } = form.data;
    const formContainerElement = getFormContainerElement({ parentElement: document.body, formID });
    hideElement(formContainerElement);

    getContext().forms.setWindowClearance(true);
    markFormClosed(mainFormId || formID);
    setFormClosedInSession(formID);
    resetFormInteracted(formID);

    showAvailableFormsTeasers();
    const teaserButton = getFormTeaserButtonElement({ parentElement: document.body, formID });
    teaserButton?.focus();

    dispatchCloseEvent({ formID });
    form.changeToFirstStep();
};
