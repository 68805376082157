export const DYNAMIC_CONTAINER_ID = 'omnisend-dynamic-container';
export const FORMS_CONTAINER_ID = 'omnisend-forms-container';
export const FORMS_TRACKING_PIXELS_CONTAINER_ID = 'omnisend-forms-tracking-pixels-container';
export const CONTACT_ID_COOKIE = 'omnisendContactID';
export const PRODUCT_REVIEW_SUBMIT_QUERY_PARAM = 'postProductReview';

export const PHONE_NUMBER_FIELD_NAME = 'phoneNumberField';
export const DATA_VALUE_ATTRIBUTE = 'data-value';

export const DAY_IN_MS = 1000 * 60 * 60 * 24;
