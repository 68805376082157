import {
    getFormWofPointerSelector,
    getFormWofPointerShadowSelector,
    getFormWofRotorSelector,
    getFormWofRotorShadowSelector,
    getFormWofRotorSliceLabelSelector,
    getFormWofRotorSliceLabelTextSelector,
    getFormWofWinningSliceA11ySelector
} from '../elements';
import { DeviceType } from '../types';
import { getContext } from '../context';

const SLICES_QUANTITY = 10;
const SLICE_DEGREES = 360 / SLICES_QUANTITY;
const ROTATE_CYCLES_COUNT = 5;
const MIN_DEGREES_ROTATE_AMOUNT = 0.03;

const POINTER_INITIAL_DEGREES = 50;
const POINTER_MAX_ROTATED_DEGREES = 3;
const POINTER_DEGREES_START = 14;
const POINTER_DEGREES_MIDDLE = 24;

const WAIT_BEFORE_FINISH = 500;

export const spinWheelOfFortune = (formID: string, blockID: string, winningSliceID: string): Promise<void> => {
    return new Promise((resolve) => {
        if (!winningSliceID) {
            resolve();
        }
        const rotor = document.querySelector(getFormWofRotorSelector(formID, blockID));
        const rotorShadow = document.querySelector(getFormWofRotorShadowSelector(formID, blockID));
        const pointer = document.querySelector(getFormWofPointerSelector(formID, blockID));
        const pointerShadow = document.querySelector(getFormWofPointerShadowSelector(formID, blockID));
        const degreesToRotate = getDegreesToRotate(formID, blockID, winningSliceID);

        const spin = (currentRotorDegrees: number, currentPointerDegrees: number): void => {
            const nextRotorDegrees = getNextRotorDegrees(currentRotorDegrees, degreesToRotate);
            const nextPointerDegrees = getNextPointerDegrees(nextRotorDegrees, currentPointerDegrees);

            rotateElement(rotor, nextRotorDegrees);
            rotateElement(rotorShadow, nextRotorDegrees);
            rotateElement(pointer, nextPointerDegrees);
            rotateElement(pointerShadow, nextPointerDegrees);

            if (nextRotorDegrees < degreesToRotate) {
                if (window.requestAnimationFrame) {
                    requestAnimationFrame(function () {
                        spin(nextRotorDegrees, nextPointerDegrees);
                    });
                } else {
                    setTimeout(function () {
                        spin(nextRotorDegrees, nextPointerDegrees);
                    }, 20);
                }
            } else {
                setTimeout(() => {
                    resolve();
                }, WAIT_BEFORE_FINISH);
            }
            announceWinningsA11y(formID, blockID, winningSliceID);
        };

        rotor?.scrollIntoView({ behavior: 'smooth' });
        spin(0, 0);
    });
};

const getDegreesToRotate = (formID: string, blockID: string, winningSliceID: string): number => {
    const winningSliceIndex = getWinningSliceIndex(formID, blockID, winningSliceID);
    const degreesToWinningSlice = winningSliceIndex > 1 ? SLICE_DEGREES * (SLICES_QUANTITY - winningSliceIndex + 1) : SLICE_DEGREES;
    return ROTATE_CYCLES_COUNT * 360 + degreesToWinningSlice;
};

const announceWinningsA11y = (formID: string, blockID: string, winningSliceID: string): void => {
    const winningSliceIndex = getWinningSliceIndex(formID, blockID, winningSliceID);
    const sliceTextElements = Array.from(document.querySelectorAll(getFormWofRotorSliceLabelTextSelector(formID, blockID)));
    const winningSliceText = sliceTextElements[winningSliceIndex]?.textContent?.trim();
    const winningSliceA11y = document.querySelector(getFormWofWinningSliceA11ySelector(formID, blockID));

    if (winningSliceA11y) {
        winningSliceA11y.textContent = `Your result from spinning the Wheel of Fortune is ${winningSliceText}`;
    }
};

const getWinningSliceIndex = (formID: string, blockID: string, winningSliceID: string): number => {
    const slicesIds = Array.from(document.querySelectorAll(getFormWofRotorSliceLabelSelector(formID, blockID))).map((slice) =>
        slice.getAttribute('id')
    );
    return slicesIds.indexOf(winningSliceID);
};

const getNextRotorDegrees = (currentRotorDegrees: number, degreesToRotate: number): number => {
    const frameSpeed = getContext().user.getDeviceType() === DeviceType.mobile ? 8 : 24;
    let degreesRotateAmount = (degreesToRotate - currentRotorDegrees) / frameSpeed / ROTATE_CYCLES_COUNT;

    if (degreesRotateAmount < MIN_DEGREES_ROTATE_AMOUNT) {
        degreesRotateAmount = MIN_DEGREES_ROTATE_AMOUNT;
    }

    const nextRotorDegrees = currentRotorDegrees + degreesRotateAmount;
    if (nextRotorDegrees + MIN_DEGREES_ROTATE_AMOUNT >= degreesToRotate) {
        return degreesToRotate;
    }

    return nextRotorDegrees;
};

const getNextPointerDegrees = (rotated: number, currentPointerDegrees: number): number => {
    const sliceRotated = rotated % SLICE_DEGREES;

    // start pointer rotation
    if (sliceRotated > POINTER_DEGREES_START && sliceRotated < POINTER_DEGREES_MIDDLE) {
        const pointerDegreesPercents = (sliceRotated - POINTER_DEGREES_START) / (POINTER_DEGREES_MIDDLE - POINTER_DEGREES_START);
        const degreesDelta = Math.abs(POINTER_INITIAL_DEGREES - POINTER_MAX_ROTATED_DEGREES) * pointerDegreesPercents;
        return POINTER_INITIAL_DEGREES - degreesDelta + 3;
    }

    // rotate pointer back
    const nextPointerDegrees = currentPointerDegrees + 7;
    if (nextPointerDegrees > POINTER_INITIAL_DEGREES) {
        return POINTER_INITIAL_DEGREES;
    }

    return nextPointerDegrees;
};

const rotateElement = (element: Element, degrees: number): void => {
    element?.setAttribute('style', `transform: rotate(${degrees}deg)`);
};
