import { nextStepAction } from '../../../actions/next-step';
import { getBlockActionSelector } from '../../../elements';
import {
    attachClickTriggerListeners,
    attachFieldsFocusListeners,
    attachFormSubmitListener,
    attachSelectFieldsListeners
} from '../../../event-listeners';
import { ActionType, Form, ListenerOptions } from '../../../types';
import { redirectAction } from '../actions/redirect';

const attachEmbeddedFormActionsListeners = ({ form, element }: ListenerOptions): void => {
    const { actions, id } = form.data;

    if (!actions) {
        return;
    }

    actions.forEach((action) => {
        const actionElement = element.querySelector(getBlockActionSelector(id, action.targetID));

        if (action.type === ActionType.redirect && actionElement) {
            attachClickTriggerListeners(actionElement, () => {
                redirectAction(action.settings.url, form);
            });
        }

        if (action.type === ActionType.nextStep && actionElement) {
            attachClickTriggerListeners(actionElement, () => {
                nextStepAction(form);
            });
        }
    });
};

export const attachEventListeners = (form: Form, formElement: HTMLElement): void => {
    attachFormSubmitListener(formElement, form);
    attachSelectFieldsListeners(formElement, form);
    attachFieldsFocusListeners(formElement, form);
    attachEmbeddedFormActionsListeners({ form, element: formElement });
};
