import { focusFormContent } from '../../accessibility';
import { submitBackInStock, subscribeForm } from '../../api';
import { initDiscountCode } from '../../components/discount-code';
import { getFormLoadingContainer, getFormSubscribedSections, getFormSuccessSections, getSubmitFormButtonSelector } from '../../elements';
import { ActionType, Form, SubmittedFormState, SubscribeFormData } from '../../types';
import { hideElement, markFormFilled, showElement } from '../../utils';
import { autoRedirectAction } from '../auto-redirect';
import { initCountdownTimer } from '../../components/countdown-timer';
import { hideCurrentStep, showNextStep } from '../next-step';
import { spinWheelOfFortune } from '../spin';
import { dispatchFormSubmitEvents } from './submit-event';
import { resetFormErrorState, handleSubmitError } from './error-handling';

const showLoader = (formID: string): void => {
    const loader = document.querySelector(getFormLoadingContainer(formID));
    showElement(loader);
};

const hideLoader = (formID: string): void => {
    const loader = document.querySelector(getFormLoadingContainer(formID));
    hideElement(loader);
};

const disableSubmitButton = (formID: string, formElement: HTMLElement): void => {
    const submitButton = formElement.querySelector(getSubmitFormButtonSelector(formID));
    if (submitButton) {
        submitButton.setAttribute('disabled', 'true');
    }
};

const enableSubmitButton = (formID: string, formElement: HTMLElement): void => {
    const submitButton = formElement.querySelector(getSubmitFormButtonSelector(formID));
    if (submitButton) {
        submitButton.removeAttribute('disabled');
    }
};

const showFormSubmittedState = (formID: string, submittedFormState: SubmittedFormState): void => {
    if (submittedFormState == SubmittedFormState.subscribed) {
        showElement(document.querySelector(getFormSubscribedSections(formID)));
    } else {
        showElement(document.querySelector(getFormSuccessSections(formID)));
    }
};

export const submitAction = async (form: Form, formElement: HTMLElement, subscribeFormData: SubscribeFormData): Promise<void> => {
    const { id: formID, actions, mainFormId } = form.data;
    const fields = form.getCurrentStepFields();

    resetFormErrorState(fields, formID, formElement);
    showLoader(formID);
    disableSubmitButton(formID, formElement);

    const submitRequest = form.isBackInStock ? submitBackInStock : subscribeForm;

    try {
        const subscribeResult = await submitRequest(form, subscribeFormData);

        dispatchFormSubmitEvents(form, subscribeFormData);

        form.saveContactIdentifier(subscribeResult);

        const { discount, formState: submittedFormState } = subscribeResult;

        markFormFilled(mainFormId || formID);
        hideLoader(formID);

        const spinAction = actions.find((action) => action.type === ActionType.spin);

        if (spinAction) {
            await spinWheelOfFortune(formID, spinAction.targetID, discount?.sliceID);
        }

        hideCurrentStep(form);

        if (!form.isLastStep()) {
            showNextStep(form);
        } else {
            showFormSubmittedState(form.data.id, submittedFormState);
            autoRedirectAction(form.data.actions);
            initDiscountCode(form.data.id, discount?.code);
        }

        initCountdownTimer(form.element, form.data);
        focusFormContent(formID);
    } catch (error: unknown) {
        hideLoader(formID);
        enableSubmitButton(formID, formElement);

        handleSubmitError(error, fields, formID, formElement);
    }
};
