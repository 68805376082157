import { DATA_VALUE_ATTRIBUTE } from '../../constants';

export const SELECT_CLASSNAMES = {
    visuallyFocusedOption: 'visually-focused-option',
    selectedOption: 'selected-option'
};

export const KEYBOARD_KEYS = {
    ENTER: 'Enter',
    SPACE: ' ',
    ESCAPE: 'Escape',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    TAB: 'Tab',
    PAGE_DOWN: 'PageDown',
    PAGE_UP: 'PageUp',
    HOME: 'Home',
    END: 'End'
};

export const NAVIGATION_KEYS = [
    KEYBOARD_KEYS.END,
    KEYBOARD_KEYS.PAGE_DOWN,
    KEYBOARD_KEYS.HOME,
    KEYBOARD_KEYS.PAGE_UP,
    KEYBOARD_KEYS.ARROW_DOWN,
    KEYBOARD_KEYS.ARROW_UP
];

export const PAGE_SIZE = 10;

export const SELECT_ATTRIBUTES = {
    VALUE: DATA_VALUE_ATTRIBUTE,
    ARIA_EXPANDED: 'aria-expanded',
    ARIA_ACTIVEDESCENDANT: 'aria-activedescendant',
    ARIA_SELECTED: 'aria-selected'
};
