import { getFormFieldDataValue, isVisibleElement } from '../../utils';
import { SELECT_ATTRIBUTES, SELECT_CLASSNAMES } from './constants';
import { selectOption, hideOptionsContainer, showOptionsContainer, markOptionVisuallySelected } from './dom-actions';
import { selectKeydownListener } from './keyboard-navigation';
import { SelectMetadata } from './types';

const markPreselectedOption = (select: HTMLElement, options: NodeListOf<Element>, metadata: SelectMetadata): void => {
    const selectedValue = select.getAttribute(SELECT_ATTRIBUTES.VALUE);

    if (selectedValue) {
        options.forEach((option) => {
            const optionValue = option.getAttribute(SELECT_ATTRIBUTES.VALUE);
            if (optionValue === selectedValue) {
                option.classList.add(SELECT_CLASSNAMES.selectedOption);
                option.setAttribute(SELECT_ATTRIBUTES.ARIA_SELECTED, 'true');
                markOptionVisuallySelected(option, metadata);
            }
        });
    }
};

export const attachSelectListeners = (metadata: SelectMetadata): void => {
    const { selectors, formElement } = metadata;
    const select = formElement.querySelector<HTMLElement>(selectors.select);
    const optionsContainer = formElement.querySelector(selectors.optionsContainer);
    const options = formElement.querySelectorAll(selectors.option);

    if (!select || !optionsContainer || !options) {
        return;
    }

    markPreselectedOption(select, options, metadata);

    const blurEventListener = (event: FocusEvent): void => {
        if (optionsContainer.contains(event.relatedTarget as Node)) {
            return;
        }

        hideOptionsContainer(metadata, true);
    };

    select.addEventListener('click', () => {
        if (isVisibleElement(optionsContainer)) {
            hideOptionsContainer(metadata);
        } else {
            if (!select.getAttribute(SELECT_ATTRIBUTES.ARIA_ACTIVEDESCENDANT)) {
                markOptionVisuallySelected(options[0], metadata);
            }
            showOptionsContainer(metadata);
        }
    });

    select.addEventListener('keydown', selectKeydownListener(metadata));
    select.addEventListener('blur', blurEventListener);
    optionsContainer.addEventListener('focusout', blurEventListener);

    options.forEach((option) => {
        option.addEventListener('click', (event: MouseEvent) => {
            const selectedOptionElement = event.currentTarget as HTMLElement;
            selectOption(selectedOptionElement, metadata, options);
        });
    });
};

export const getFormFieldSelectValue = (select: Element): string => {
    return getFormFieldDataValue(select);
};
