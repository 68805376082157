import { ListenerOptions } from '../../../../types';
import { getCloseActionSelector } from '../../../../elements';
import { attachClickTriggerListeners } from '../../../../event-listeners';
import { safeExecute } from '../../../../utils';
import { closeAction } from '../actions/close';

export const attachFormCloseListeners = ({ form, element, additionalActions = [] }: ListenerOptions): void => {
    const { data } = form;
    const { id } = data;
    const closeElement = element.querySelector(getCloseActionSelector(id));

    if (closeElement) {
        attachClickTriggerListeners(
            closeElement,
            () => {
                closeAction(form);
                additionalActions.map(safeExecute);
            },
            true
        );
    }
};
