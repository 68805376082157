import { submitAction } from '../actions/submit/submit';
import { getFormFieldSelectValue } from '../components/select/select';
import {
    getFormFieldRadioValueSelector,
    getFormFieldSelectSelector,
    getPhoneNumberPrefixSelector,
    getSubmitFormSelector
} from '../elements';
import { ActionType, AdditionalActions, Field, Form, FormDataValue, SubscribeFormData } from '../types';
import { getFormFieldCheckboxValues, getFormFieldDataValue, isFieldTypeCheckbox, isFieldTypeRadio, isFieldTypeSelect } from '../utils';

const attachSubmitListener = (el: Element, listener: () => unknown): void => {
    el.addEventListener('submit', function submitListener(event) {
        event.preventDefault();
        listener();
    });
};

const getFieldValue = (formID: string, field: Field, formElement: HTMLFormElement): FormDataValue => {
    const formData = new FormData(formElement);

    if (field.name === 'phoneNumberCountryCodeField') {
        return getFormFieldSelectValue(formElement.querySelector(getPhoneNumberPrefixSelector(formID, field.targetID)));
    }

    if (isFieldTypeSelect(formElement, formID, field)) {
        return getFormFieldSelectValue(formElement.querySelector(getFormFieldSelectSelector(formID, field.targetID, field.name)));
    }

    if (isFieldTypeRadio(formElement, formID, field)) {
        return getFormFieldDataValue(formElement.querySelector(getFormFieldRadioValueSelector(formID, field.targetID, field.name)));
    }

    if (isFieldTypeCheckbox(formElement, formID, field)) {
        return getFormFieldCheckboxValues(formElement, formID, field);
    }

    return formData.get(field.name);
};

const parseSubmitFormData = (formElement: HTMLFormElement, fields: Field[] = [], formID: string): SubscribeFormData => {
    return fields.reduce((result, field) => {
        const fieldValue = getFieldValue(formID, field, formElement);

        return fieldValue
            ? {
                  ...result,
                  [field.name]: fieldValue
              }
            : result;
    }, {});
};

const attachFormStepSubmitListener = (
    formElement: HTMLElement,
    form: Form,
    stepID: string,
    additionalActions?: AdditionalActions
): void => {
    const { id: formID } = form.data;

    const submitFormElement: HTMLFormElement = formElement.querySelector(getSubmitFormSelector(formID, stepID));
    if (!submitFormElement) {
        return;
    }

    attachSubmitListener(submitFormElement, async () => {
        const stepFields = form.getCurrentStepFields();
        const subscribeFormData = parseSubmitFormData(submitFormElement, stepFields, formID);

        await submitAction(form, submitFormElement, subscribeFormData);

        additionalActions && additionalActions.forEach((additionalAction) => additionalAction());
    });
};

export const attachFormSubmitListener = (formElement: HTMLElement, form: Form, additionalActions?: AdditionalActions): void => {
    const { actions, steps } = form.data;

    const isSubmitAction = actions.find((action) => action.type === ActionType.submit);
    if (!isSubmitAction) {
        return;
    }

    if (!form.isMultistep) {
        attachFormStepSubmitListener(formElement, form, form.getCurrentStepID(), additionalActions);
        return;
    }

    steps.forEach((step) => attachFormStepSubmitListener(formElement, form, step.ID, additionalActions));
};
