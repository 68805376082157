import { dispatchStepSubmitEvent, dispatchSubmitEvent } from '../../event-dispatchers';
import { Form, Step, SubscribeFormData } from '../../types';

const formSubmittedById: Record<string, boolean> = {};

const setFormSubmitted = (formID: string): void => {
    formSubmittedById[formID] = true;
};

const isFormSubmitted = (formID: string): boolean => {
    return formSubmittedById[formID];
};

export const dispatchFormSubmitEvents = (form: Form, formValues: SubscribeFormData): void => {
    const formID = form.data.id;

    if (!isFormSubmitted(formID)) {
        dispatchSubmitEvent({ formID: formID, formValues });
        setFormSubmitted(formID);
    }

    if (!form.isMultistep) {
        return;
    }

    const step: Step = form.isLastStep() ? '2' : '1';
    dispatchStepSubmitEvent({ formID: formID, formValues, step });
};
