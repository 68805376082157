import { getFormStepElement } from '../elements';
import { trackInteraction } from '../tracking';
import { Form } from '../types';
import { hideElement, showElement } from '../utils';
import { initCountdownTimer } from '../components/countdown-timer';
import { dispatchStepViewEvent } from '../event-dispatchers';
import { focusFormContent } from '../accessibility';

export const hideCurrentStep = (form: Form): void => {
    const { id: formID } = form.data;
    const currentStepID = form.getCurrentStepID();

    const currentStep = getFormStepElement({
        parentElement: document.body,
        formID,
        stepID: currentStepID
    });

    hideElement(currentStep);
};

export const showNextStep = (form: Form): void => {
    form.changeToNextStep();

    const nextStep = getFormStepElement({
        parentElement: document.body,
        formID: form.data.id,
        stepID: form.getCurrentStepID()
    });

    showElement(nextStep);
    dispatchStepViewEvent({ formID: form.data.id, step: '2' });
};

export const nextStepAction = async (form: Form): Promise<void> => {
    await trackInteraction(form);
    hideCurrentStep(form);
    showNextStep(form);
    initCountdownTimer(form.element, form.data);
    focusFormContent(form.data.id);
};
